import Tippy from '@tippyjs/react'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  Link,
  Loader,
  LoadingText,
  SpinnerStyles,
  TableStyles,
  ViewHistory,
  Wrapper,
} from './styles'
import RedaptiveReactTable7 from '../../../components/RedaptiveReactTable7'
import TableHead from '../../../components/RedaptiveReactTable7/TableHead'
import Spinner from '../../../components/Spinner'
import { Cell } from '../styles'
import { defaultMeterInstallWidths, meterTableHeaders } from './constants'
import {
  actions as meterInstallActions,
  selectMeterGroupedSumissions,
} from '../../../ducks/meterInstallSubmissions/meterInstallGroupSubmissions'

export default function MeterTable({ opportunityId }) {
  const dispatch = useDispatch()
  const {
    meters: { data: meterData },
    orderByMeters,
  } = useSelector((state) => selectMeterGroupedSumissions(state))

  const { results = [], loading } = meterData?.[opportunityId] || {}
  const orderBy = orderByMeters?.[opportunityId]
  const [orderParams, setOrderParams] = useState(orderBy)
  useEffect(() => {
    // first api call
    if (!results.length) {
      setOrderParams({ field: 'modified', sort: 'DESC' })
      dispatch(
        meterInstallActions.fetchMeterData({
          opportunityId,
          orderBy: {
            field: 'modified',
            sort: 'DESC',
          },
          pageSize: 1000,
        }),
      )
    }
  }, [opportunityId])

  useEffect(() => {
    if (
      results.length &&
      JSON.stringify(orderBy) !== JSON.stringify(orderParams)
    ) {
      dispatch(
        meterInstallActions.fetchMeterData({
          opportunityId,
          orderBy: orderParams,
          pageSize: 1000,
        }),
      )
    }
  }, [opportunityId, orderParams])

  const handleSortClick = useCallback(
    ({
      currentTarget: {
        dataset: { id },
      },
    }) => {
      if (orderParams?.field) {
        if (orderParams.field !== id || orderParams.sort === 'DESC') {
          setOrderParams({
            field: id,
            sort: 'ASC',
          })
        } else {
          setOrderParams({
            field: id,
            sort: 'DESC',
          })
        }
      }
    },
    [orderParams],
  )

  const tableHeaderRows = useMemo(() => {
    const showSorting = (item) =>
      item.id === 'macAddress' ||
      item.id === 'modified' ||
      item.id === 'createdBy'
    const headerRows = meterTableHeaders.map((item) => ({
      id: item.id,
      label: item.label,
      handleSortClick: showSorting(item) ? handleSortClick : () => {},
      sortable: showSorting(item),
      sorted: orderParams?.field === item.id,
      sortDesc: orderParams?.sort === 'DESC',
      minWidth: defaultMeterInstallWidths[item.id]?.minWidth,
      maxWidth: defaultMeterInstallWidths[item.id]?.maxWidth,
    }))
    return [
      {
        id: 'row2',
        headers: headerRows,
      },
    ]
  }, [orderParams, handleSortClick])

  const TableHeadComponent = () => <TableHead rows={tableHeaderRows} />

  const getColumnCell = useCallback((cellProps) => {
    const {
      row: {
        original: { id: submissionId, meterId },
      },
      column: { id },
      value,
    } = cellProps
    switch (id) {
      case 'macAddress':
        return (
          <Link href={`/account-management/meters/${meterId}`}>{value}</Link>
        )
      case 'history':
        return <ViewHistory href=''>View History</ViewHistory>
      case 'configAcceptedDate':
        return `${moment(value).format('MM/DD/YYYY, hh:mm:ss A')} UTC`
      case 'modified':
        return (
          <Link href={`/reports/meter-install-submissions/${submissionId}`}>
            {moment(value).format('MM/DD/YYYY, hh:mm:ss A')} UTC
          </Link>
        )
    }
    return (
      (
        <Tippy content={value}>
          <Cell maxWidth={defaultMeterInstallWidths[id]?.maxWidth}>
            {value}
          </Cell>
        </Tippy>
      ) || '-'
    )
  }, [])

  const columns = useMemo(
    () =>
      meterTableHeaders.map((item) => ({
        accessor: item.id,
        Cell: getColumnCell,
        Header: item.label,
        minWidth: defaultMeterInstallWidths[item.id]?.minWidth,
        maxWidth: defaultMeterInstallWidths[item.id]?.maxWidth,
      })),
    [getColumnCell],
  )
  if (loading) {
    return (
      <Wrapper>
        <SpinnerStyles>
          <Loader>
            <Spinner size='small' />
            <LoadingText>Loading...</LoadingText>
          </Loader>
        </SpinnerStyles>
      </Wrapper>
    )
  }
  if (results.length) {
    return (
      <tr>
        <Wrapper>
          <TableStyles>
            <RedaptiveReactTable7
              columns={columns}
              data={results}
              globalFilterable={false}
              TableHead={TableHeadComponent}
              showTableScrollArrows={false}
            />
          </TableStyles>
        </Wrapper>
      </tr>
    )
  }
  return null
}
